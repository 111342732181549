import React from 'react';

function RefundPoliyPage() {
    return (
        <div className="m-10 leading-relaxed">
            <h1 className="text-2xl font-semibold" id="refund-policy">Refund Policy</h1>
            <p className="mt-3">Planning to upgrade to Pickmystylist Premium, but you want to check out the features to assure yourself.
                Pickmystylist is offering a 14-days subscription for the free trial.</p>
            <p className="mt-3">After the completion of the trial period, upgrade your account to Premium, and pay at the time of upgrade.
                In case you are not satisfied with our software, then you have to claim the below refund policy:</p>
            <h2 className="text-xl font-semibold my-3" id="how-to-ask-a-refund">How to ask a refund</h2>
            <p className="mt-3">To refund, you need to mail us at support@pickmystylist.com. The subject line would be &quot;Subscription Refund&quot;.
                Also, mention the business name and email address connected with the account.
                Mail your request in 30 days after signing up with Pickmystylist.</p>
            <h2 className="text-xl font-semibold my-3" id="how-the-grace-period-goes">How the grace period goes</h2>
            <p className="mt-3">To ask for a refund, you should downgrade and forward the request within the first 30 days after completing the process of signing up.
                The requests given after 30 days might not get refunded.</p>
            <h2 className="text-xl font-semibold my-3" id="processing-time">Processing time</h2>
            <p className="mt-3">Generally, the processing of refund takes around 5 to 7 working days after receiving the request.</p>
        </div>
    );
}

export default RefundPoliyPage;